import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"

import pageCss from "@css/components/common/page.module.styl"
import missionCss from "@css/components/recruit/mission.module.styl"
import memberCss from "@css/components/recruit/member.module.styl"
import wantedCss from "@css/components/recruit/wanted.module.styl"

import Footer from '@components/Footer'

class Privacy extends React.PureComponent {
    render(){
        const { data } = this.props
        const { bg01 } = data
        return(
            <div>
                <SEO title="プライバシーポリシー" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <h1 className={pageCss.title_mid}>
                                    特定商取引法に基づく表記
                                </h1>
                            </div>
                            <div className={pageCss.term}>
                                <section>
                                    <h2>特定商取引法に基づく表記</h2>
                                    <section>
                                        <h2>販売価格（役務の対価）</h2>
                                        <p>
                                        <b>・SUGARコイン</b><br/>
                                        SUGARコインの購入画面に記載のとおり<br/>
                                        <br/>
                                        <b>・個別有料サービス</b><br/>
                                        各サービスの購入画面に記載のとおり
                                        </p>
                                    </section>
                                    <section>
                                        <h2>対価の支払時期・方法</h2>
                                        <p>
                                        <b>・SUGARコイン</b><br/>
                                        Apple Inc.及びその子会社、 Google LLC及びその子会社、お客様が選択した支払方法に関するクレジットカード会社等の規約に基づきお支払いいただきます。<br/>
                                        <br/>
                                        <b>・個別有料サービス</b><br/>
                                        購入時にSUGARコインにてお支払いいただきます。
                                        </p>
                                    </section>
                                    <section>
                                        <h2>商品の引渡時期（役務の提供時期）</h2>
                                        <p>
                                        ご購入手続後直ちに前払式支払手段としてご利用可能です。
                                        </p>
                                    </section>
                                    <section>
                                        <h2>返金の特約</h2>
                                        <p>
                                        商品又はサービスの特性上、法令において特に義務付けられる場合を除き、購入後の返金、撤回及び解除は一切お受けできません。
                                        </p>
                                    </section>
                                    <section>
                                        <h2>事業者の名称・住所・代表者名・連絡先</h2>
                                        <p>
                                        名　称：SUGAR株式会社<br/>
                                        住　所：〒107-0061 東京都港区北青山3-6-7 青山パラシオタワー11F<br/>
                                        代表者：鎌田和樹<br/>
                                        連絡先：legal@sugarcorp.zendesk.com<br/>
                                        電　話：弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。弊社電話番号が必要なお客様は上記アドレスよりお申し出ください。
                                        </p>
                                    </section>
                                    <section>
                                        <h2>販売価格（役務の対価）以外にお客様にご負担いただく金銭</h2>
                                        <p>
                                        SUGARコイン・個別有料サービスに関するサイトの閲覧、コンテンツのダウンロード及びアプリケーション動作時における通信に必要な通信料が発生します。
                                        </p>
                                    </section>
                                    <section>
                                        <h2>商品又はサービス内容に瑕疵がある場合の責任</h2>
                                        <p>
                                        弊社の故意又は重大な過失による場合を除き、商品又はサービスの瑕疵について一切責任を負わないものとします。
                                        </p>
                                    </section>
                                    <section>
                                        <h2>動作環境</h2>
                                        <p>
                                        コンテンツ又はアプリケーションによって利用環境・対応環境が異なります。ダウンロードの前に、各コンテンツ又はアプリケーションの詳細ページでご確認ください。
                                        </p>
                                    </section>
                                    <section>
                                        <p>
                                        以上<br/>
                                        <br/>
                                        制定日：2019年08月01日<br/>
                                        最終改訂日：2020年02月10日
                                        </p>
                                    </section>
                                </section>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default Privacy


export const query = graphql`
    query {
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-02@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
